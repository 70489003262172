const FORBID_TAGS = ['script', 'style', 'iframe', 'frame', 'object', 'embed', 'form', 'base', 'meta'];

const ALLOWED_ATTR = [
  'href',
  'target',
  'rel',
  'title',
  'alt',
  'src',
  'width',
  'height',
  'class',
  'id',
  'name',
  'style',
  'align',
  'valign',
  'colspan',
  'rowspan',
  'data-attachmentid', // used to identify attachments in the editor
];

const ADD_ATTR = ['rel'];

const USE_PROFILES = { html: true, svg: true, svgFilters: true };

export { ALLOWED_ATTR, FORBID_TAGS, ADD_ATTR, USE_PROFILES };
