import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { findValue, FIELD_ATTRIBUTES_TYPE } from './useGetListValueById';

const useHiddenSelectOption = (field) => {
  const [hiddenSelectOption, setHiddenSelectOption] = useState();
  const queryClient = useQueryClient();

  useEffect(() => {
    let isMount = true;

    async function fetch() {
      if (!field?.value) {
        return;
      }

      const value = await findValue({
        id: field.value,
        list: field.list,
        listKey: field.fieldId,
        customColumn: false,
        isMultiple: false,
        valueType: FIELD_ATTRIBUTES_TYPE,
        isWorkflowApi: false,
        queryClient,
        ignoreList: false,
      });

      if (isMount) {
        setHiddenSelectOption(value);
      }
    }

    fetch();
    return () => {
      isMount = false;
    };
  }, [field, queryClient]);

  return hiddenSelectOption;
};

export default useHiddenSelectOption;
