import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { ActivePopupName, selectActivePopups } from 'store/activePopups';
import { disableActivePopup as disableActivePopupRequest } from 'services/activePopups';
import { useQueryClient } from '@tanstack/react-query';
import { GET_ACTIVE_POPUPS_QUERY_KEY } from 'common/constants/popups';

const useDisableActivePopup = (activePopupName: ActivePopupName): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const activePopups = useSelector(selectActivePopups);
  const disableActivePopup = useCallback(async () => {
    try {
      const activePopup = activePopups.find((popup) => popup.name === activePopupName);
      if (activePopup) {
        await disableActivePopupRequest(activePopup.id);
        queryClient.invalidateQueries({ queryKey: [GET_ACTIVE_POPUPS_QUERY_KEY] });
      }
    } catch (error) {
      console.error(error);
    }
  }, [activePopups, queryClient, activePopupName]);

  return disableActivePopup;
};
export default useDisableActivePopup;
