import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { setPopups } from 'store/activePopups';
import { getActivePopups } from 'services/activePopups';
import { GET_ACTIVE_POPUPS_QUERY_KEY } from 'common/constants/popups';

const queryFn = async () => {
  try {
    const response = await getActivePopups();
    // @ts-ignore
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

const useInitializeActivePopups = (): void => {
  const dispatch = useDispatch();

  const { data } = useQuery([GET_ACTIVE_POPUPS_QUERY_KEY], {
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0,
  });

  useEffect(() => {
    if (data) {
      dispatch(setPopups(data));
    }
  }, [data, dispatch]);
};

export default useInitializeActivePopups;
