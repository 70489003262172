/* this hook will fetch custom value from the BE only incase it does not exist in the list */
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getFieldAttributes } from 'services/ticketService';
import {
  convertValue,
  convertValues,
} from 'features/srPanel/templateFieldsGrid/fieldSearchableDropdown/values-convert';
import { getWorkflowFieldAttributes } from 'features/resolutionPanel/middlePanel/Workflows/api/workflowService';
import { CC_USER_FIELD_ID, REQUEST_USER_FIELD_ID, USER_MANAGER_FIELD_ID } from 'features/queue/grid/constants';
import { OMIT_API_SEARCH } from 'features/resolutionPanel/middlePanel/relatedItems/constants';
import {
  convertFromClientFieldId,
  fieldValueExists,
  getIsUserFieldByFieldId,
  getValueKeyByFieldId,
} from '../fieldUtils';
import { areObjectsEqual, expandQueryKey, mergeArraysByKey } from '../utils';
import { QUERY_STALE_TIME } from '../../../constant';

export const FIELD_ATTRIBUTES_TYPE = 'field_attribute_type';

const supportedZeroValueListKeys = [];

function searchInCache({ listKey, customColumn, valueType, queryObject, queryClient }) {
  const fieldId = convertFromClientFieldId(listKey, customColumn);
  const requestKey = expandQueryKey([valueType, fieldId, customColumn], queryObject);
  return queryClient.getQueryData(requestKey);
}

function searchInList({ ignoreList, list, value, listKey }) {
  const convertedList = convertValues(list);
  let foundInList = null;
  // sometimes the list parameter just haven't got time to be updated so we're returning null until it does, and sometimes we intentially don't send the list and just search/update the cache so we're using ignoreList to distinguish between the cases
  if (!ignoreList) {
    if (!list?.length) {
      return null;
    }
    const keyString = getValueKeyByFieldId(listKey);

    foundInList = convertedList?.find((v) => String(v[keyString]) === String(value));
    if (!foundInList && listKey === USER_MANAGER_FIELD_ID) {
      // If user manager isn't found by username, then using email for search
      foundInList = convertedList?.find((v) => String(v['email']) === String(value));
    }
  }
  return foundInList;
}

async function searchInApi({ valueType, listKey, customColumn, queryObject, isWorkflowApi, queryClient }) {
  let response;
  const fieldId = convertFromClientFieldId(listKey, customColumn);
  if (fieldId === OMIT_API_SEARCH) return [];

  let baseQueryKey;
  if (valueType === FIELD_ATTRIBUTES_TYPE) {
    baseQueryKey = ['fieldAttribute', fieldId, customColumn];
    const fullQueryKey = expandQueryKey(baseQueryKey, queryObject);

    try {
      // Try to get from cache first
      response = queryClient.getQueryData(fullQueryKey);

      // If not in cache, fetch it
      if (!response) {
        response = await queryClient.fetchQuery({
          queryKey: fullQueryKey,
          queryFn: async () => {
            if (isWorkflowApi) {
              return getWorkflowFieldAttributes(fieldId, queryObject);
            }
            return getFieldAttributes(fieldId, queryObject);
          },
          staleTime: QUERY_STALE_TIME.DEFAULT,
        });
      }
    } catch (error) {
      console.error(`Failed to fetch field attributes for field ${fieldId}:`, error);
      return [];
    }
  } else {
    throw new Error('Unsupported value type: ', valueType);
  }

  const responseValues = response?.values;
  const objectsToReturn = [];

  if (responseValues) {
    responseValues.forEach((responseValue) => {
      const objectToReturn = { ...convertValue(responseValue), fieldName: response.fieldName };
      // Cache individual values
      const valueQueryKey = [...baseQueryKey, 'value', responseValue.id];
      queryClient.setQueryData(valueQueryKey, objectToReturn);
      objectsToReturn.push(objectToReturn);
    });
  }

  return objectsToReturn;
}

export async function findValue(params) {
  const {
    list,
    id,
    listKey,
    customColumn,
    valueType = FIELD_ATTRIBUTES_TYPE,
    isMultiple,
    isWorkflowApi,
    queryClient,
    ignoreList,
  } = params;

  const queryObject = {};
  let cachedValue;
  let foundInList;
  let valuesFromApi;

  if (list === null || (Array.isArray(list) && list.length === 0)) {
    return null;
  }

  if (!fieldValueExists({ value: id, fieldId: listKey })) {
    return null;
  }

  if (listKey === undefined || listKey === null) return null;
  if (supportedZeroValueListKeys.indexOf(listKey) === -1 && id === 0) return null;

  const isUserField = getIsUserFieldByFieldId(listKey);

  if (isUserField) {
    queryObject.usernames = isMultiple ? id.map((item) => encodeURIComponent(item)).join(',') : encodeURIComponent(id);
  } else {
    queryObject.id = id;
  }

  if (customColumn) {
    queryObject.customColumn = customColumn;
  }

  if (isMultiple) {
    const objectsToReturn = [];
    const values = id;

    values.forEach((value, index) => {
      cachedValue = searchInCache({
        listKey,
        customColumn,
        valueType,
        queryObject: isUserField ? { username: queryObject.usernames?.[index] } : { id: queryObject.ids?.[index] },
        queryClient,
      });

      if (cachedValue) {
        objectsToReturn.push(cachedValue);
      } else {
        foundInList = searchInList({ ignoreList, list, listKey, value });
        if (foundInList) {
          objectsToReturn.push(foundInList);
        } else if (listKey === CC_USER_FIELD_ID) {
          objectsToReturn.push({
            id: value,
            valueCaption: value,
            value,
          });
        }
      }
    });
    if (objectsToReturn.length === values.length) {
      return objectsToReturn;
    }

    valuesFromApi = await searchInApi({ valueType, listKey, customColumn, queryObject, isWorkflowApi, queryClient });

    return mergeArraysByKey(objectsToReturn, valuesFromApi, 'id');
  }
  cachedValue = searchInCache({
    listKey,
    customColumn,
    valueType,
    queryObject: isUserField ? { username: queryObject.usernames?.[0] } : queryObject,
    queryClient,
  });

  if (cachedValue) {
    return cachedValue;
  }
  foundInList = searchInList({ ignoreList, list, listKey, value: id });
  if (foundInList) {
    return foundInList;
  }
  valuesFromApi = await searchInApi({ valueType, listKey, customColumn, queryObject, isWorkflowApi, queryClient });
  if (valuesFromApi.length > 0) {
    return valuesFromApi[0];
  }

  return null;
}

const valueIs = (value, values) => values.includes(value);

export const useGetListValueById = ({
  listKey,
  customColumn = false,
  list,
  id,
  isMultiple,
  isWorkflowApi,
  valueType,
  ignoreList,
}) => {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const queryClient = useQueryClient();
  const prevData = useRef(null);

  useEffect(() => {
    let isMount = true;
    async function fetch() {
      let value = await findValue({
        id,
        list,
        listKey,
        customColumn,
        isMultiple,
        valueType,
        isWorkflowApi,
        queryClient,
        ignoreList,
      });
      //In some cases tickets are created by non user with e-mail message and because of that API wouldnt return user information in the the list.
      //In that case to show proper UI we have to imitate user information by only data that we have its e-mail.
      if (!value && id && listKey === REQUEST_USER_FIELD_ID) {
        value = {
          valueCaption: id,
          id,
          value: id,
        };
      }
      if (isMount) {
        setLoaded(
          (value !== null && id) ||
            (value === null && valueIs(id, ['', 'none', 0, '0', undefined, null])) ||
            (id && listKey && value === null),
        );
        if (!areObjectsEqual(prevData.current, value)) {
          setData(value);
          prevData.current = value;
        }
      }
    }
    fetch();
    return () => {
      isMount = false;
    };
  }, [listKey, customColumn, id, list, isMultiple, valueType, isWorkflowApi, ignoreList, queryClient]);

  return { data, loaded };
};
