import { useSelector } from 'react-redux';
import { selectTicketSummaryDetails } from 'store/ticketSummarySlice';
import { activeUsername } from 'store/userSlice';
import { APP_CONSTANTS } from 'constants/app';
import { SummaryPopup } from 'common/components/summaryPopup';

export const useTicketSummary = (popperRef) => {
  const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);
  const username = useSelector(activeUsername);
  const { title, srId: tooltipSrId } = useSelector(selectTicketSummaryDetails);

  return (
    <SummaryPopup title={title} accountId={accountId} userId={username} srId={tooltipSrId} popperRef={popperRef} />
  );
};
