import { useCallback } from 'react';
import { useRouter } from '@tanstack/react-router';
import { useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { selectActiveUser } from 'store/userSlice';
import { APP_CONSTANTS } from 'constants/app';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { PENDO_TRACK_EVENTS, DATADOG_TRACK_EVENTS } from '../eventMapping';

export default function useAnalytics() {
  const { data: isPendoEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.PENDO_TRACKING_ENABLED,
    defaultValue: true,
  });

  const { data: isDatadogEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.DATADOG_TRACKING_ENABLED,
    defaultValue: false,
  });

  const currentUser = useSelector(selectActiveUser);
  const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);
  const router = useRouter();
  const srId = router.latestLocation.search.id;

  const trackEvents = useCallback((eventName, eventData) => {
    const trackingData = {
      srId,
      currentUser,
      accountId,
      ...eventData,
    };

    if (isPendoEnabled) {
      const pendoEventName = PENDO_TRACK_EVENTS[eventName];
      window.pendo?.track(pendoEventName, trackingData);
    }

    if (isDatadogEnabled) {
      const datadogEventName = DATADOG_TRACK_EVENTS[eventName];
      datadogRum.addAction(datadogEventName, trackingData);
    }

  }, [accountId, currentUser, srId, isDatadogEnabled, isPendoEnabled]);

  return trackEvents;
}
